import { Typography, Chip } from '@material-ui/core';
import useStyles from '../style/styles';


const SkillHighlights = () => {
    const classes = useStyles();


    return (
        <div variant='h5' color="textSecondary">
            <Typography>Programmiersprachen</Typography>
            <div className={classes.chipgroup}>
                <Chip label="Java"></Chip>
                <Chip label="Typescript"></Chip>
                <Chip label="Javascript"></Chip>
                <Chip label="C#"></Chip>
                <Chip label="Ruby"></Chip>
            </div>
            <Typography>Frameworks</Typography>
            <div className={classes.chipgroup}>
                <Chip label="Ruby on Rails"></Chip>
                <Chip label="Angular"></Chip>
                <Chip label="React"></Chip>
            </div>
            <Typography>Versionsverwaltung</Typography>
            <div className={classes.chipgroup}>
                <Chip label="Git"></Chip>
                <Chip label="SVN"></Chip>
            </div>
            <Typography>Web</Typography>
            <div className={classes.chipgroup}>
                <Chip label="HTML"></Chip>
                <Chip label="CSS"></Chip>
            </div>
            <Typography>Entwicklungsumgebungen</Typography>
            <div className={classes.chipgroup}>
                <Chip label="IntelliJ IDEA"></Chip>
                <Chip label="Eclipse"></Chip>
                <Chip label="Visual Studio Code"></Chip>
            </div>
            <Typography>Server</Typography>
            <div className={classes.chipgroup}>
                <Chip label="Liferay"></Chip>
                <Chip label="Apache"></Chip>
                <Chip label="Tomcat"></Chip>
            </div>
            <Typography>Datenbanken</Typography>
            <div className={classes.chipgroup}>
                <Chip label="MySQL"></Chip>
                <Chip label="MariaDB"></Chip>
                <Chip label="MS SQL"></Chip>
            </div>
            <Typography>Projektmanagement</Typography>
            <div className={classes.chipgroup}>
                <Chip label="Jira"></Chip>
                <Chip label="Confluence"></Chip>
            </div>
            <Typography>Continuous Integration</Typography>
            <div className={classes.chipgroup}>
                <Chip label="Jenkins"></Chip>
            </div>
            <Typography>Sprachen</Typography>
            <div className={classes.chipgroup}>
                <Chip label="Englisch"></Chip>
                <Chip label="Deutsch"></Chip>
            </div>
        </div>
    );
}


export default SkillHighlights;