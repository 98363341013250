import Header from "../components/Header";
import Footer from "../components/Footer";
import { useLocalState } from "../util/UseLocalState";
import { Card, Divider, CardContent, CardHeader, CardActions } from '@material-ui/core';
import GoToRoot from "../components/GoToRoot";
import { Typography } from '@material-ui/core';
import { Link } from "react-router-dom";
import Button from '@material-ui/core/Button';


const Projects = () => {
    const [theme, setTheme] = useLocalState("theme", "light");

    return (
        <div>
            <Header theme={theme} setTheme={setTheme} />
            <main>
                <div>
                    <Card>
                        <CardHeader title="private Tools/Projects" titleTypographyProps={{ variant: 'h4' }}>
                        </CardHeader>
                        <Divider />
                        <CardContent>
                            <Typography variant="button" >
                                <Button variant="text" size="small" href="https://github.com/ale-wol/java-metric-analyzer" target="_blank">Java Metric Analyzer</Button >
                            </Typography>
                            <Typography variant="body2" >
                                Small Java Console Application to scan an Java Project an calculate some source code quality metrics.
                            </Typography>
                            <br></br>
                            <Typography variant="button" >
                                <Button size="small" component={Link} to="/Unminifi" name="goToUnminifiCSS">CSS Unminifier</Button >
                            </Typography>
                            <Typography variant="body2" >
                                Javascript Tool that uses Regular Expression to convert Minified CSS to unminified CSS.
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <GoToRoot />
                        </CardActions>
                    </Card>
                </div>
            </main>
            <Footer />
        </div>
    );
}


export default Projects;