import Header from "../components/Header";
import Footer from "../components/Footer";
import { useLocalState } from "../util/UseLocalState";
import { Typography } from "@material-ui/core";
import { Card, Divider, CardContent, CardHeader, CardActions } from '@material-ui/core';
import GoToRoot from "../components/GoToRoot";
import useStyles from '../style/styles';



const Imprint = () => {
    const [theme, setTheme] = useLocalState("theme", "light");

    const classes = useStyles();

    return (
        <div>
            <Header theme={theme} setTheme={setTheme} />
            <main>
                <div>
                    <Card>
                        <CardHeader title="Impressum" titleTypographyProps={{ variant: 'h4' }}>
                        </CardHeader>
                        <Divider />
                        <CardContent className={classes.imprint}>
                                <Typography component="p">
                                Alexander Wolf
                                </Typography>
                                <Typography component="p">
                                96450 Coburg
                                </Typography>
                                <Typography component="p">
                                Kiefernweg 8
                                </Typography>
                                <Typography component="p">
                                Deutschland
                                </Typography>
                        </CardContent>
                        <CardActions>
                            <GoToRoot />
                        </CardActions>
                    </Card>
                </div>
            </main>
            <Footer />
        </div>
    );
}


export default Imprint;