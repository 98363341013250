import { Typography } from '@material-ui/core';
import useStyles from '../style/styles';
import Link from '@material-ui/core/Link';


const AboutMe = () => {
    const classes = useStyles();

    return (
        <div className={classes.aboutMe} color="primary">
                <Typography variant='h5' color="textSecondary">
                    Hi, ich bin Full Stack Software Entwickler aus Coburg in Oberfranken und arbeite derzeit als Senior Consultant bei der
                    <Link href="https://www.mid.de/" target="_blank" rel="noopener" > MID GmbH</Link>.
                    Mein besonders Interesse liegt im stetigen Auseinandersetzten mit neuen Technologien und im besten Fall den produktiven Einsatz von diesen.
                    Ich startete meine Karriere 2013 mit Ruby on Rails und bin über verschiedene Java und Javascript Technologien zu meinem momentanen Entwicklungsfokus 
                    von Springboot im Backend und Javascript/Typescript im Frontend gekommen. 
                </Typography>
        </div>
    );
}


export default AboutMe;