import { createTheme } from '@material-ui/core/styles';




export const lightTheme = createTheme({
  palette: {
    palette: {
      type: 'light',
    },
    background: {
      default: '#d3d3d3',
      paper: '#FFFFFF',
    },
    text: {
      primary: '#000000',
      secondary: '#000000',
    },
    divider: '#000000',
    action: {
    },
  },
});

export const darkTheme = createTheme({
  palette: {
    type: 'dark',
  },
  info: {
    light: '#809fff',
    main: '#809fff',
    dark: '#809fff',
  },
});
