import { IconButton, Toolbar } from '@material-ui/core';
import useStyles from '../style/styles';
import Brightness4 from '@material-ui/icons/Brightness4';
import Code from '@material-ui/icons/Code';
import Mail from '@material-ui/icons/Mail';
import GitHub from '@material-ui/icons/GitHub';
import CV from '../static/files/CV_AlexanderWolf.pdf';
import Tooltip from '@material-ui/core/Tooltip';
import { Link } from "react-router-dom";
import Button from '@material-ui/core/Button';



const HeaderMobile = ({ theme, setTheme }) => {
    const classes = useStyles();

    function themeToggler() {
        //console.log("Theme: " + theme);
        //console.log("trigger switch Theme!!");
        theme === "light" ? setTheme("dark") : setTheme("light");
        theme === "light" ? localStorage.setItem("theme", JSON.stringify("dark")) : localStorage.setItem("theme", JSON.stringify("light"));
        window.location.reload(true);
    };

    function sendMail() {
        window.open('mailto:info@alexander-wolf-co.de');
    };

    return (
        <Toolbar>
            <Button className={classes.ukraine}>
                ALEXANDER WOLF
            </Button>

            <Tooltip title="Projects">
                <IconButton component={Link} to="/Projects" name="goToProjects">
                    <Code />
                </IconButton>
            </Tooltip>

            <Tooltip title="Download CV">
                <Button href={CV} target="_blank" rel='noopener noreferrer'>
                    CV
                </Button>
            </Tooltip>

            <Tooltip title="Github">
                <IconButton href="https://github.com/ale-wol" target="_blank">
                    <GitHub />
                </IconButton>
            </Tooltip>

            <Tooltip title="Mail">
                <IconButton onClick={sendMail}>
                    <Mail />
                </IconButton>
            </Tooltip>

            <Tooltip title="Toggle light/dark Mode">
                <IconButton onClick={themeToggler} name="toggleTheme">
                    <Brightness4 />
                </IconButton>
            </Tooltip>
        </Toolbar>
    );
}


export default HeaderMobile;