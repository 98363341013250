import {  Grid, Card, Divider, CardContent, CardHeader } from '@material-ui/core';
import clsx from "clsx";
import useStyles from '../style/styles';
import AboutMe from './AboutMe';
import SkillHighlights from './SkillHighlights';
import Career from './Career';
import Education from './Education';



const ResGrid = () => {
    const classes = useStyles();

    return (
        <div className={clsx(classes.gridRoot)}>
            <Grid container direction="row" spacing={2} justifyContent="center">
                <Grid item container direction="column" xs={12} md={7} spacing={2}>
                    <Grid item xs>
                        <div className={classes.container}>
                            <Card>
                                <CardHeader title="Über mich" titleTypographyProps={{ variant: 'h4' }}>
                                </CardHeader>
                                <Divider />
                                <CardContent>
                                    <AboutMe />
                                </CardContent>
                            </Card>
                        </div>
                    </Grid>

                    <Grid item xs>
                        <div className={clsx(classes.container)}>
                            <Card>
                                <CardHeader title="Werdegang" titleTypographyProps={{ variant: 'h4' }}>
                                </CardHeader>
                                <Divider />
                                <CardContent>
                                    <Career />
                                </CardContent>
                            </Card>
                        </div>
                    </Grid>

                    <Grid item xs>
                        <div className={clsx(classes.container)}>
                            <Card>
                                <CardHeader title="Ausbildung" titleTypographyProps={{ variant: 'h4' }}>
                                </CardHeader>
                                <Divider />
                                <CardContent>
                                    <Education />
                                </CardContent>
                            </Card>
                        </div>
                    </Grid>
                </Grid>

                <Grid item xs={12} md={3}>
                    <div className={classes.containerRight}>
                        <Card>
                            <CardHeader title="Skills" titleTypographyProps={{ variant: 'h4' }}>
                            </CardHeader>
                            <Divider />
                            <CardContent>
                                <SkillHighlights />
                            </CardContent>
                        </Card>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

export default ResGrid;