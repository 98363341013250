import { ArrowBack } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import Tooltip from '@material-ui/core/Tooltip';
import { Link } from "react-router-dom";



const GoToRoot = () => {

    return (
        <Tooltip title="Go Back">
            <IconButton component={Link} to="/" aria-label="zurück">
                <ArrowBack />
            </IconButton>
        </Tooltip>
    );
}

export default GoToRoot;