import useStyles from "../style/styles";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useLocalState } from "../util/UseLocalState";
import ResGrid from "../components/ResGrid";

const Home = () => {
    const classes = useStyles();
    const [theme, setTheme] = useLocalState("theme", "light");
    
    return (
        <div className={classes.home}>
            <Header theme={theme} setTheme={setTheme} />
            <main>
                <div>
                    <ResGrid />
                </div>
            </main>
            <Footer />
        </div>
    );
}

export default Home;