import { Typography } from '@material-ui/core';
import useStyles from '../style/styles';
import { Link } from "react-router-dom";


const Footer = () => {
    const classes = useStyles();


    return (
        <footer className={classes.footer}>
            <Typography className={classes.footerLinks} >
                <Link className={classes.link} to="/Imprint">Impressum</Link>
                <Link className={classes.link} to="/Disclaimer" >Haftungsausschluss</Link>
                <Link className={classes.link} to="/PrivacyPolicy">Datenschutzerklärung</Link>
            </Typography>
        </footer>
    );
}

export default Footer;