import Header from "../components/Header";
import Footer from "../components/Footer";
import { useLocalState } from "../util/UseLocalState";
import { IconButton } from "@material-ui/core";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { Card, Divider, CardContent, CardHeader, CardActions } from '@material-ui/core';
import { Search } from "@material-ui/icons";
import GoToRoot from "../components/GoToRoot";
import Tooltip from '@material-ui/core/Tooltip';


const Unminifi = () => {
    const [theme, setTheme] = useLocalState("theme", "light");

    function UnminifiCSS() {
        var result = document.getElementById('minified-css-new'),
            code;
        code = document.getElementById('minified-css-orig').value
            .split('{').join(' {\n    ')
            .split(';').join(';\n    ')
            .split(',').join(', ')
            .split('    }').join('}\n')
            .replace(/\}(.+)/g, '}\n$1')
            .replace(/\n {4}([^:]+):/g, '\n    $1: ')
            .replace(/([A-z0-9)])}/g, '$1;\n}');
        result.style.display = 'block';
        result.value = code;
    };

    return (
        <div>
            <Header theme={theme} setTheme={setTheme} />
            <main>
                <div>
                    <Card>
                        <CardHeader title="CSS Unminifier" titleTypographyProps={{ variant: 'h4' }}>
                        </CardHeader>
                        <Divider />
                        <CardContent>
                            <TextareaAutosize
                                id="minified-css-orig"
                                maxRows={20}
                                minRows={20}
                                aria-label="maximum height"
                                placeholder="Paste your minified CSS here"
                                defaultValue="Paste your minified CSS here."
                                style={{ width: 800 }}
                            />

                            <TextareaAutosize
                                id="minified-css-new"
                                hidden="true"
                                disabled="true"
                                maxRows={20}
                                minRows={20}
                                aria-label="maximum height"
                                style={{ width: 800 }}
                            />
                        </CardContent>
                        <CardActions>
                            <GoToRoot />
                            <Tooltip title="Unminifi CSS">
                                <IconButton onClick={UnminifiCSS} aria-label="zurück">
                                    <Search />
                                </IconButton>
                            </Tooltip>
                        </CardActions>
                    </Card>
                </div>
            </main>
            <Footer />
        </div>
    );
}


export default Unminifi;