import Header from "../components/Header";
import Footer from "../components/Footer";
import { useLocalState } from "../util/UseLocalState";
import { Typography } from "@material-ui/core";
import { Card, Divider, CardContent, CardHeader, CardActions } from '@material-ui/core';
import GoToRoot from "../components/GoToRoot";


const PrivacyPolicy = () => {
    const [theme, setTheme] = useLocalState("theme", "light");

    return (
        <div>
            <Header theme={theme} setTheme={setTheme} />
            <main>
                <div>
                    <Card>
                        <CardHeader title="Datenschutz" titleTypographyProps={{ variant: 'h4' }}>
                        </CardHeader>
                        <Divider />
                        <CardContent>
                            <Typography component="p">
                                Beim Zugriff auf diese Webseite werden automatisch allgemeine Informationen (sog. Server-Logfiles) erfasst. Diese beinhalten u.a. den von Ihnen verwendeten Webbrowser sowie Ihr Betriebssystem und Ihren Internet Service Provider. Diese Daten lassen keinerlei Rückschlüsse auf Ihre Person zu und werden statistisch ausgewertet, um diesen Internetauftritt technisch und inhaltlich zu verbessern.
                            </Typography>
                            <br/>
                            <Typography component="p">
                                Die Nutzung dieser Webseite ist grundsätzlich ohne Angabe personenbezogener Daten möglich. Soweit personenbezogene Daten (beispielsweise Name, Anschrift oder E-Mail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben.
                            </Typography>
                            <br/>
                            <Typography component="p">
                                Sofern ein Vertragsverhältnis begründet, inhaltlich ausgestaltet oder geändert werden soll oder Sie mir eine Anfrage stellen, erhebe und verwende ich personenbezogene Daten von Ihnen, soweit dies zu diesem Zwecke erforderlich ist (Bestandsdaten). Ich erhebe, verarbeite und nutze personenbezogene Daten soweit dies erforderlich ist, um Ihnen die Inanspruchnahme des Webangebots zu ermöglichen (Nutzungsdaten). Sämtliche personenbezogenen Daten werden nur solange gespeichert wie dies für den genannten Zweck (Bearbeitung Ihrer Anfrage oder Abwicklung eines Vertrags) erforderlich ist. Hierbei werden steuer- und handelsrechtliche Aufbewahrungsfristen von mir berücksichtigt. Auf Anordnung der zuständigen Stellen muss ich im Einzelfall Auskunft über diese Daten (Bestandsdaten) erteilen, soweit dies für Zwecke der Strafverfolgung, zur Gefahrenabwehr, zur Erfüllung der gesetzlichen Aufgaben der Verfassungsschutzbehörden oder des Militärischen Abschirmdienstes oder zur Durchsetzung der Rechte am geistigen Eigentum erforderlich ist.
                            </Typography>
                            <br/>
                            <Typography component="p">
                                Es wird ausdrücklich darauf hingewiesen, dass die Datenübertragung im Internet (z. B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Vor dem Zugriff auf Daten kann nicht lückenlos geschützt werden.
                            </Typography>
                            <br/>
                            <Typography component="p">
                                Die Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit ausdrücklich untersagt. Ausgenommen hiervon sind bestehende Geschäftsbeziehungen bzw. es liegt Ihnen eine entsprechende Einwilligung von mir vor.
                            </Typography>
                            <br/>
                            <Typography component="p">
                                Die Anbieter und alle auf dieser Website genannten Dritten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen vor. Gleiches gilt für die kommerzielle Verwendung und Weitergabe der Daten.
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <GoToRoot />
                        </CardActions>
                    </Card>
                </div>
            </main>
            <Footer />
        </div>
    );
}


export default PrivacyPolicy;