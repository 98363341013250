import { makeStyles } from '@material-ui/core';
import code from '../static/images/code.jpg'; 

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(${code})`,
    backgroundSize: "100% auto",
  },

  gridRoot: {
  },

  appBar: {
    backgroundColor: theme.palette.background.paper,
    marginBottom: "20px",
  },

  ukraine: {
    background: "linear-gradient(0, rgba(255,215,0,1) 0%, rgba(255,215,0,1) 50%, rgba(0, 87, 183,1) 50%, rgba(0, 87, 183,1) 100%)", 
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontSize: "125%",
    cursor: "auto"
  },

  headerLeft: {
    position: "absolute",
    left: "2%"
  },

  headerRight: {
    position: "absolute",
    right: "2%"
  },

  container: {
    textAlign: "center",
    minHeight: 150, // Give minimum height to a div
    height: "100%", // So that grid 4 go all the way down
  },

  list: {
    textAlign: "left"
  },

  avatarList: {
    display: 'flex',
  },

  vertical:{
    borderLeft:'1px solid lightgrey',
    height:'3em',
    marginLeft:'2.1em',
  },

  containerRight: {
    textAlign: "center",
    marginRight: "15px",
    marginBottom: "5px",
    height: "100%", // So that grid 4 go all the way down
  },

  aboutMe: {
    display: 'flex',
    alignItems: 'center',
  },

  avatar: {
    width: theme.spacing(20),
    height: theme.spacing(20),
  },


  chipgroup: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },

  footer: {
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    left: '0px',
    bottom: '0px',
    height: '10px',
    width: '100%',
    padding: '40px 0',
    marginTop: "20px",
  },

  footerLinks: {
    marginLeft: theme.spacing(2),
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },

  link: {
    color: "#809fff"
  },

  alignRight: {
    float: "right"
  },

  imprint: {
    display: "inline-block"
  }

}));

export default useStyles