import * as React from 'react';
import { Typography, List, ListItem, ListItemIcon, ListItemText} from '@material-ui/core';
import useStyles from '../style/styles';
import ArrowRight from '@material-ui/icons/ArrowRight';



const Career = () => {
    const classes = useStyles();


    return (
        <div className={classes.list}>
            <Typography variant='h5' color="textSecondary">
                MID GmbH
            </Typography>
            <Typography color="textSecondary">
                Senior Software Engineer
            </Typography>
            <Typography color="textSecondary">
                01/2023-Aktuell
            </Typography>
            <Typography color="textSecondary">Ausgewählte Referenzen:</Typography>
            <List sx={{ width: '100%' }} >
                <ListItem disablepadding="true">
                    <ListItemIcon>
                        <ArrowRight />
                    </ListItemIcon>
                    <ListItemText primary="Weiterentwicklung bzw. Neuentwicklung verschiedener OSLC Plugins" />
                </ListItem>
                <ListItem disablepadding="true">
                    <ListItemIcon>
                        <ArrowRight />
                    </ListItemIcon>
                    <ListItemText primary="Weiterenwicklung eines Spring Boot OSLC Frameworks" />
                </ListItem>
                <ListItem disablepadding="true">
                    <ListItemIcon>
                        <ArrowRight />
                    </ListItemIcon>
                    <ListItemText primary="Spring, OSLC, Atlassian Datacenter Plugins, Java, Angular" />
                </ListItem>
            </List>

            <Typography variant='h5' color="textSecondary">
                MID GmbH
            </Typography>
            <Typography color="textSecondary">
                Senior Consultant
            </Typography>
            <Typography color="textSecondary">
                06/2022-12/2022
            </Typography>
            <Typography color="textSecondary">Ausgewählte Referenzen:</Typography>
            <List sx={{ width: '100%' }} >
                <ListItem disablepadding="true">
                    <ListItemIcon>
                        <ArrowRight />
                    </ListItemIcon>
                    <ListItemText primary="Weiterentwicklung einer internen Auftragsverwaltung" />
                </ListItem>
                <ListItem disablepadding="true">
                    <ListItemIcon>
                        <ArrowRight />
                    </ListItemIcon>
                    <ListItemText primary="Spring, Angular, Agil" />
                </ListItem>
            </List>

            <Typography variant='h5' color="textSecondary">
                Coperitus GmbH
            </Typography>
            <Typography color="textSecondary">
                Professional Software Engineer
            </Typography>
            <Typography color="textSecondary">
                01/2021-05/2022
            </Typography>
            <Typography color="textSecondary">Ausgewählte Referenzen:</Typography>
            <List sx={{ width: '100%' }} >
                <ListItem disablepadding="true">
                    <ListItemIcon>
                        <ArrowRight />
                    </ListItemIcon>
                    <ListItemText primary="Weiterentwicklung von Liferay Brokerage Portalen" />
                </ListItem>
                <ListItem disablepadding="true">
                    <ListItemIcon>
                        <ArrowRight />
                    </ListItemIcon>
                    <ListItemText primary="Java, Liferay, Agil" />
                </ListItem>
            </List>

            <Typography variant='h5' color="textSecondary">
                Crealogix BaaS GmbH
            </Typography>
            <Typography color="textSecondary" >
                Professional Software Engineer
            </Typography>
            <Typography color="textSecondary" >
                08/2018-12/2020
            </Typography>
            <Typography color="textSecondary">Ausgewählte Referenzen:</Typography>
            <List sx={{ width: '100%' }} >
                <ListItem disablepadding="true">
                    <ListItemIcon>
                        <ArrowRight />
                    </ListItemIcon>
                    <ListItemText primary="Implementierung von einer Kurspushfunktion in einem Liferay Brokerageportal" />
                </ListItem>
                <ListItem disablepadding="true">
                    <ListItemIcon>
                        <ArrowRight />
                    </ListItemIcon>
                    <ListItemText primary="Entwicklung eines Liferay B2B Brokerageportals" />
                </ListItem>
                <ListItem disablepadding="true">
                    <ListItemIcon>
                        <ArrowRight />
                    </ListItemIcon>
                    <ListItemText primary="Entwicklung einer Spring Boot Rest Schnittstelle" />
                </ListItem>
                <ListItem disablepadding="true">
                    <ListItemIcon>
                        <ArrowRight />
                    </ListItemIcon>
                    <ListItemText primary="Java, Liferay, Agil" />
                </ListItem>
            </List>

            
            <Typography variant='h5' color="textSecondary">
                Elaxy BSS
            </Typography>
            <Typography color="textSecondary">
                Professional Software Engineer
            </Typography>
            <Typography color="textSecondary">
                07/2016-07/2018
            </Typography>
            <Typography color="textSecondary">Ausgewählte Referenzen:</Typography>
            <List sx={{ width: '100%' }} >
                <ListItem disablepadding="true">
                    <ListItemIcon>
                        <ArrowRight />
                    </ListItemIcon>
                    <ListItemText primary="Entwicklung eines Javatools zum automatischen Download von Berichten" />
                </ListItem>
                <ListItem disablepadding="true">
                    <ListItemIcon>
                        <ArrowRight />
                    </ListItemIcon>
                    <ListItemText primary="Entwicklung eines Liferay Brokerage Endkundenportals" />
                </ListItem>
                <ListItem disablepadding="true">
                    <ListItemIcon>
                        <ArrowRight />
                    </ListItemIcon>
                    <ListItemText primary="Java, Liferay, Agil" />
                </ListItem>
            </List>


        </div>
    );
}


export default Career;