import { AppBar } from '@material-ui/core';
import useStyles from '../style/styles';
import HeaderDesktop from './HeaderDesktop';
import HeaderMobile from './HeaderMobile';

const Header = ({theme, setTheme}) => {
    const classes = useStyles();
    
    return (
        <AppBar className={classes.appBar} position="relative" color="inherit">
           {window.innerWidth > 1200 ? <HeaderDesktop theme={theme} setTheme={setTheme}></HeaderDesktop> : <HeaderMobile theme={theme} setTheme={setTheme}></HeaderMobile>}
        </AppBar>
    );
}


export default Header;