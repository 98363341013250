import React from 'react';
import useStyles from './style/styles';
import { lightTheme, darkTheme } from './style/Themes';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { useLocalState } from './util/UseLocalState';
import CustomRouter from './components/Router';


const App = () => {
  const classes = useStyles();
  const [theme] = useLocalState("theme", "dark");

  window.addEventListener('storage', () => {
    //console.log("local Storage Changed!!!");
    //console.log("Theme: " + theme);
    window.location.reload(true);
  });


  return (
    <React.StrictMode>

      <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
        <CssBaseline />
        <div className={classes.root}>
          <CustomRouter />
        </div>
      </ThemeProvider>
    </React.StrictMode>
  );
}

export default App;
