import Home from '../pages/Home';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import Disclaimer from '../pages/Disclaimer';
import Imprint from '../pages/Imprint';
import Unminifi from '../pages/Unminifi';
import Projects from '../pages/Projects';

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

const CustomRouter = () => {

    return (
        <Router>
        <Routes>
            <Route path='/' element={<Home />}></Route>
            <Route path='*' element={404}></Route>
            <Route index element={<Home />}></Route>
            <Route path='/PrivacyPolicy' element={<PrivacyPolicy />}></Route>
            <Route path='/Imprint' element={<Imprint />}></Route>
            <Route path='/Disclaimer' element={<Disclaimer />}></Route>
            <Route path='/Unminifi' element={<Unminifi />}></Route>
            <Route path='/Projects' element={<Projects />}></Route>
        </Routes>
      </Router>
    );
}


export default CustomRouter;