import { Typography } from '@material-ui/core';
import useStyles from '../style/styles';


const Education = () => {
    const classes = useStyles();

    return (
        <div className={classes.list}>
            <Typography variant='h5' color="textSecondary">
                Elaxy BSS/IHK Coburg
            </Typography>
            <Typography color="textSecondary">
                Fachinformatiker für Anwendungsentwicklung
            </Typography>
            <Typography color="textSecondary">
                2013-2016
            </Typography>

            <Typography variant='h5' color="textSecondary">
                Universität Würzburg
            </Typography>
            <Typography color="textSecondary">
                Chemie
            </Typography>
            <Typography color="textSecondary">
                2011-2013
            </Typography>
            
            <Typography variant='h5' color="textSecondary">
                Fachoberschule Coburg
            </Typography>
            <Typography color="textSecondary">
                Allgemeine Hochschulreife
            </Typography>
            <Typography color="textSecondary">
                2008-2011
            </Typography>

        </div>
    );
}


export default Education;